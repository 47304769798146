import React, { useEffect, useState } from "react";
import { Destination, FormField } from "@prequel/react";
import {
  Checkbox,
  RadioCardOption,
  RadioCards,
  SectionExpandable,
} from "@prequel-internal/react-components";

import { useTypedSelector } from "../../../../store";
import { selectOrg } from "../../../../store/org/org.duck";

type AdvancedOptionsSectionProps = {
  field: FormField;
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[] | number
  ) => void;
  disabled: boolean;
};
const AdvancedOptionsSection = ({
  field,
  destination,
  setDestinationField,
  disabled,
}: AdvancedOptionsSectionProps) => {
  const org = useTypedSelector(selectOrg);
  const [frequencyOptions, setFrequencyOptions] =
    useState<RadioCardOption<number>[]>();
  const [orgDefault, setOrgDefault] = useState<RadioCardOption<number>>();
  useEffect(() => {
    // When we load the org, set the frequency options
    if (field.form_element === "radio") {
      const items: RadioCardOption<number>[] = field.enum
        .filter(({ key }) => key !== 0)
        .map((e) => ({
          value: parseInt(e.key.toString()),
          label: e.display,
          enabled: !e.disabled,
        }));

      setFrequencyOptions(items);
      const def = items.find(
        ({ value }) => value === org?.default_frequency_minutes
      );
      setOrgDefault(def);
    }
  }, [field]);

  return (
    <SectionExpandable label="Advanced options">
      {/* Using separate label so checkbox can be underneath it */}
      <div className="flex items-center justify-between">
        <h2 className={"text-sm font-medium text-gray-900 mb-3"}>
          Sync Frequency
        </h2>
      </div>
      <Checkbox
        key="use_default_frequency"
        id="use_default_frequency"
        label={
          <span>
            Use default frequency
            {orgDefault ? (
              <span className="text-gray-400 font-normal">
                {" "}
                ({orgDefault.label})
              </span>
            ) : (
              ""
            )}
          </span>
        }
        checked={destination.frequency_minutes === 0}
        setChecked={(isChecked: boolean) =>
          isChecked
            ? setDestinationField("frequency_minutes", 0)
            : setDestinationField(
                "frequency_minutes",
                org?.default_frequency_minutes ?? 1440
              )
        }
        disabled={disabled}
      />
      <RadioCards
        options={frequencyOptions ?? []}
        selectedOption={frequencyOptions?.find(
          ({ value }) => value === destination.frequency_minutes
        )}
        setSelectedOption={({ value }) =>
          setDestinationField("frequency_minutes", value)
        }
        disabled={disabled || destination.frequency_minutes === 0}
      />
    </SectionExpandable>
  );
};

export default AdvancedOptionsSection;
