import React, { useState, useRef } from "react";

import {
  Button,
  ButtonStyle,
  Form,
  FormField,
  InlineText,
  Modal,
} from "@prequel-internal/react-components";

import { ReactComponent as AlertIcon } from "../../assets/icons/alert-triangle.svg";

type ConfirmDataDestinationModalProps = {
  kind: "GENERATE_LINK" | "ADD_DESTINATION";
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  dataIdentifier: string;
  dataDestination: {
    host?: string;
    bucket_name?: string;
  };
  onConfirm: () => void;
};
const ConfirmDataDestinationModal = ({
  kind,
  show,
  setShow,
  dataIdentifier,
  dataDestination,
  onConfirm,
}: ConfirmDataDestinationModalProps) => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const validate = (value: string) =>
    setSubmitEnabled(value === dataIdentifier);

  const onChange = (value: string) => {
    setInputValue(value);
    validate(value);
  };

  const onClose = (show: boolean) => {
    setInputValue("");
    setShow(show);
    setSubmitEnabled(false);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal open={show} onClose={onClose}>
      <div>
        <div className="flex flex-column">
          <div className="mr-4 flex items-center justify-center h-10 w-10 rounded-full bg-red-100">
            <AlertIcon
              className="h-6 w-6 text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            />
          </div>
          <div>
            <Modal.Title
              as="h3"
              className="text-xl leading-6 font-bold text-gray-900"
            >
              Send data to new destination
            </Modal.Title>
            <span className="text-sm text-gray-500">
              Please confirm the following details.
            </span>
          </div>
        </div>
        <div className="px-14 mt-2 text-sm text-gray-500 font-semilight">
          <div className="mb-2">
            You are about to send data from: <br />
            <InlineText value={dataIdentifier} /> <br />
            to <br />
            {dataDestination.host && (
              <InlineText value={dataDestination.host} />
            )}
            {dataDestination.host && dataDestination.bucket_name && (
              <>
                {" via the bucket"} <br />
              </>
            )}
            {dataDestination.bucket_name && (
              <InlineText value={dataDestination.bucket_name} />
            )}
          </div>
          <p>
            {`Before you
            ${
              kind === "GENERATE_LINK"
                ? "generate this link"
                : "add this destination"
            }, please be sure that the organization ID and host are associated
            with the same account.`}
          </p>
        </div>
      </div>
      {/* divider */}
      <div className="-mx-6 h-px w-lg bg-gray-200 my-3"></div>
      {/* Assigning "onConfirm" to "onSubmit" here will allow the user to leverage the browser defaults (like using "Enter" to confirm form submission)*/}
      {/* Assigning the "buttonRef" to "submitButtonRef" here will allow Form level "hotkeys" (e.g., "Cmd+Enter") to simulate a submission */}
      <Form className="px-8" onSubmit={onConfirm} submitButtonRef={buttonRef}>
        <div className="px-8 mb-3 text-center">
          <p className="text-sm text-gray-500">
            {"Please type "}
            <span className="font-bold text-gray-800">{dataIdentifier}</span>
            {" to confirm."}
          </p>
        </div>
        <FormField
          id="organization_id"
          type="text"
          value={inputValue}
          onChangeHandler={onChange}
        />
        <Button
          text={`The details above are correct, ${
            kind === "GENERATE_LINK" ? "generate link" : "add destination"
          }`}
          className="mt-3 w-full"
          type={ButtonStyle.DANGER}
          disabled={!submitEnabled}
          submit // By making this button a "Submit" button, the user can click this button to "Submit" the form, to which we've provided the "onConfirm" function at the form level
          ref={buttonRef}
        />
      </Form>
    </Modal>
  );
};

export default ConfirmDataDestinationModal;
