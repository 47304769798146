import React, { useEffect } from "react";
import {
  checkTabIsActive,
  PageHeading,
  Tabs,
} from "@prequel-internal/react-components";
import { useNavigate, NavLink, useRoutes } from "react-router-dom";

import ModelsTable from "./ModelsTable";
import ProductConfigsTable from "./ProductsTable";
import ModelPage from "./ModelPage";
import ProductForm from "./ProductsForm";

const ModelsPage = () => {
  const tabs = [
    <NavLink
      key="Models"
      to="/export/models"
      className={checkTabIsActive}
      replace
      end
    >
      Models
    </NavLink>,
    <NavLink
      key="Products"
      to="/export/models/products"
      className={checkTabIsActive}
      replace
      end
    >
      Products
    </NavLink>,
  ];
  const navigate = useNavigate();

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Models & Products" />
          <Tabs tabs={tabs} />
          <ModelsTable />
        </div>
      ),
    },
    {
      path: "products",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Models & Products" />
          <Tabs tabs={tabs} />
          <ProductConfigsTable />
        </div>
      ),
    },
    {
      path: ":modelId",
      element: (
        <div className="max-w-4xl">
          <ModelPage />
        </div>
      ),
    },
    {
      path: "products/new",
      element: (
        <div className="max-w-lg">
          <PageHeading title="Add Product" onGoBack={() => navigate(-1)} />
          <ProductForm />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col pb-4">{element}</div>;
};

export default ModelsPage;
