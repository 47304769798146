import {
  configureStore,
  combineReducers,
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { all, call, put } from "redux-saga/effects";

import { AppError, injectStore } from "../axios";
import destinationsReducer, {
  destinationsSaga,
} from "./destinations/destinations.duck";
import productsReducer, { productsSaga } from "./products/products.duck";
import sourcesReducer, { sourcesSaga } from "./sources/sources.duck";
import transfersReducer, { transfersSaga } from "./transfers/transfers.duck";
import transfersReportReducer, {
  transfersReportSaga,
} from "./reports/transfers_report.duck";
import magicLinksReducer, {
  magicLinksSaga,
} from "./magic_links/magic_links.duck";
import orgReducer, { orgSaga } from "./org/org.duck";
import userReducer, { userSaga } from "./user/user.duck";
import authReducer, {
  authSaga,
  updateEnvironmentSuccess,
} from "./auth/auth.duck";
import toastsReducer, { toastsSaga } from "./toasts/toasts.duck";
import sshReducer, { sshSaga } from "./ssh/ssh.duck";
import recipientsReducer, {
  recipientsSaga,
} from "./recipients/recipients.duck";
import modelsReducer, { modelsSaga } from "./models/models.duck";

// R = Request Payload, S = Success Payload
type ApiCall<R, S> = (payload: R) => Promise<S>;
export const createWorkerSaga = <R, S>(
  request:
    | ActionCreatorWithPayload<R, string>
    | ActionCreatorWithoutPayload<string>,
  success: ActionCreatorWithPayload<S, string>,
  failure: ActionCreatorWithPayload<AppError, string>,
  api: ApiCall<R, S>
) => {
  return function* saga({ payload }: { type: string; payload: R }) {
    try {
      const res: S = yield call(api, payload);
      yield put(success(res));
    } catch (error: any) {
      const e: AppError = error;
      yield put(failure(e));
    }
  };
};

type RedirectPayload = { redirect: () => void };
export type WithRedirect<T> = T & RedirectPayload;
export const createRedirectSaga = () => {
  return function* saga({
    payload,
  }: {
    type: string;
    payload: RedirectPayload;
  }) {
    yield payload.redirect();
  };
};

const reducer = combineReducers({
  destinations: destinationsReducer,
  sources: sourcesReducer,
  transfers: transfersReducer,
  transfers_report: transfersReportReducer,
  magic_links: magicLinksReducer,
  org: orgReducer,
  user: userReducer,
  auth: authReducer,
  toasts: toastsReducer,
  models: modelsReducer,
  ssh: sshReducer,
  recipients: recipientsReducer,
  products: productsReducer,
});

function* rootSaga() {
  yield all([
    destinationsSaga(),
    sourcesSaga(),
    transfersSaga(),
    transfersReportSaga(),
    magicLinksSaga(),
    orgSaga(),
    userSaga(),
    authSaga(),
    toastsSaga(),
    modelsSaga(),
    sshSaga(),
    recipientsSaga(),
    productsSaga(),
  ]);
}

const rootReducer = (state: any, action: any) => {
  if (action.type == updateEnvironmentSuccess.type) {
    // if we successfully updated the environment, reset the store
    state = undefined;
  }
  return reducer(state, action);
};
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        // Overrides the serializable rule of the payload.redirect field
        ignoredActionPaths: ["payload.redirect"],
      },
    }).concat([sagaMiddleware]),
  devTools: true,
});
sagaMiddleware.run(rootSaga);
// inject redux store into axios
injectStore(store);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
