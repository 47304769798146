import React, { useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import { fetchOrg, selectOrg } from "../../../store/org/org.duck";
import { fetchUser, selectUser } from "../../../store/user/user.duck";

type ProfileMenuProps = {
  menuItems: Array<{ text: string; onClick: () => void }>;
};

const ProfileMenu = ({ menuItems }: ProfileMenuProps) => {
  const dispatch = useTypedDispatch();
  const org = useTypedSelector(selectOrg);
  const user = useTypedSelector(selectUser);

  useEffect(() => {
    dispatch(fetchOrg());
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <Menu as="div" className="flex inline-block px-3 text-left">
      <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-left text-sm font-medium text-slate-700 hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-slate-100">
        <span className="flex w-full items-center justify-between">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-800">
              <span className="text-lg font-normal leading-none text-white">
                {user?.email.toUpperCase().slice(0, 2)}
              </span>
            </span>
            <span className="flex min-w-0 flex-1 flex-col space-y-0.5">
              <span className="truncate text-sm font-normal text-slate-600">
                {user?.email}
              </span>
              <span className="truncate text-xs font-normal text-slate-400">
                {org?.name}
              </span>
            </span>
          </span>
        </span>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute w-60 right-0 left-0 z-10 mx-60 mt-1 origin-top divide-y divide-slate-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 w-full">
            {menuItems.map((menuItem, key) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button
                    onClick={menuItem.onClick}
                    className={`${
                      active ? "bg-slate-100 text-slate-900" : "text-slate-700"
                    }
                  flex px-4 py-2 text-sm w-full
                `}
                  >
                    {menuItem.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default ProfileMenu;
