import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavigationItem from "./NavigationItem";
import ProfileMenu from "./ProfileMenu";
import { ReactComponent as Logo } from "../../assets/logos/prequel.svg";
import { Toggle, DropdownListItem } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../store";

import {
  selectEnvironment,
  updateEnvironment,
  resetAuth,
  fetchLogOut,
} from "../../store/auth/auth.duck";
import { dropdownEnvironments, Environment } from "../../store/auth";

const Navigation = () => {
  const dispatch = useTypedDispatch();
  const [currentEnvironment, setCurrentEnvironment] = useState<
    DropdownListItem<Environment>
  >(dropdownEnvironments[0]);
  const environment = useTypedSelector(selectEnvironment);

  const navigate = useNavigate();

  const onLogoutHandler = () => {
    dispatch(
      fetchLogOut({
        redirect: () => navigate("/login"),
      })
    );
  };

  useEffect(() => {
    const e = dropdownEnvironments.find(({ key }) => key === environment);
    e && setCurrentEnvironment(e);
  }, [environment]);
  return (
    <div className="flex w-64 flex-col inset-y-0 bg-slate-50">
      <div className="flex-1 flex flex-col min-h-0 border-r border-slate-200">
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="flex h-12 px-6 items-center flex-shrink-0 border-b border-slate-200">
            <Logo className="h-4" />
          </div>
          <nav className="flex-0 mt-3 px-3 space-y-1.5 pb-4 border-b border-slate-200">
            <NavigationItem to="/export/overview" title="Overview" />
            <NavigationItem to="/export/sources" title="Sources" />
            <NavigationItem to="/export/models" title="Models" />
            <NavigationItem to="/export/recipients" title="Recipients" />
            <NavigationItem to="/export/destinations" title="Destinations" />
            <NavigationItem to="/export/magic-links" title="Magic Links" />
          </nav>
          <nav className="flex-1 mt-3 px-3 space-y-1.5 pb-4 border-b border-slate-200 ">
            <h1 className="flex items-center px-3 py-1 text-sm rounded-sm text-slate-400 font-normal cursor-default">
              Documentation
            </h1>
            <NavigationItem
              to="https://docs.prequel.co/docs"
              title="Guides"
              isExternal
            />
            <NavigationItem
              to="https://docs.prequel.co/reference"
              title="API Reference"
              isExternal
            />
          </nav>

          <div className="flex-0 space-y-3 items-center py-4">
            <Toggle
              label={
                <span className="text-slate-400 font-normal">
                  Env.{" "}
                  <span className="text-slate-600">
                    {currentEnvironment.text}
                  </span>
                </span>
              }
              enabled={currentEnvironment.key === Environment.PROD}
              setEnabled={(isEnabled: boolean) =>
                dispatch(
                  updateEnvironment({
                    environment: isEnabled
                      ? Environment.PROD
                      : Environment.STAGING,
                  })
                )
              }
              className="w-full px-6 justify-between	"
              size="sm"
            />
            <ProfileMenu
              menuItems={[{ text: "Logout", onClick: () => onLogoutHandler() }]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
