import {
  PreparedDestination,
  ExistingDestination,
  Transfer,
} from "@prequel/react";
import { formatDistanceToNow, parseJSON } from "date-fns";

import { RequestStatus } from "../../axios";

// Using this enum for the type values of the destination interfaces
// allows us easier type detection and to create discriminated unions

export type DestinationRequest = {
  status: RequestStatus;
  message?: string;
};

export type TestDestinationResponse = {
  status: RequestStatus;
};

export type TestDestinationResult = {
  status: RequestStatus;
  message: string;
};

export type ExistingDestinationPayload = {
  destinationId: ExistingDestination["id"];
  fields?: Partial<PreparedDestination>;
};

export enum DestinationTenanting {
  BY_ID = 0,
  BY_RECIPIENT = 1,
}

export const getReadableLastSuccessfulSync = (
  lastSync?: Transfer["ended_at"]
) => {
  if (!lastSync) {
    return "Has not transferred successfully";
  }

  const lastSyncDate = parseJSON(lastSync);
  return formatDistanceToNow(lastSyncDate, { addSuffix: true });
};

export const sortByLastSuccessfulSync = (
  destA: ExistingDestination,
  destB: ExistingDestination
) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(destA.last_completed_transfer?.ended_at ?? nullDate);
  const dateB = parseJSON(destB.last_completed_transfer?.ended_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};

export const isExistingDestination = (
  obj: ExistingDestination | PreparedDestination
): obj is ExistingDestination => "id" in obj;

export const getReadableModels = (models: string[] | undefined): string => {
  if (!models || models.length === 0) {
    return "No models";
  }

  if (models.length === 1 && models[0] === "*") {
    return "All available models";
  }

  return models.join(", ");
};
