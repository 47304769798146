import React from "react";
import {
  RadioButtons,
  RadioCardOption,
  RadioCards,
  Toggle,
} from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";

import VendorLogo from "../../../../components/VendorLogo";

type RadioWrapperProps = {
  field: FormField & { form_element: "radio" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};
const RadioWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: RadioWrapperProps) => {
  if (field.type === "boolean") {
    return (
      <div key={field.name}>
        <Toggle
          label={field.label}
          enabled={!!destination[field.name]}
          setEnabled={(e: boolean) => setDestinationField(field.name, e)}
        />
      </div>
    );
  } else if (
    field.name === "auth_method" ||
    field.name === "bucket_auth_method"
  ) {
    const items: RadioCardOption<string>[] = field.enum.map((e) => ({
      value: e.key.toString(),
      label: e.display,
      enabled: true,
      icon: e.icon_url ? <VendorLogo logo_url={e.icon_url} /> : <></>,
    }));
    const selected = items.find(
      ({ value }) => value === destination[field.name]
    );

    return (
      <RadioCards
        size="md"
        label={field.label}
        options={items}
        selectedOption={selected}
        setSelectedOption={(opt) => setDestinationField(field.name, opt.value)}
        disabled={disabled}
      />
    );
  } else {
    const items = field.enum.map((e) => ({
      key: e.key.toString(),
      name: e.display,
      content: e.key,
    }));
    const selected = items.find(({ key }) => key === destination[field.name]);

    return (
      <div key={field.name}>
        <RadioButtons
          label={field.label}
          options={items}
          selectedOption={selected}
          setSelectedOption={(selected) =>
            setDestinationField(field.name, selected.key)
          }
          disabled={disabled}
        />
      </div>
    );
  }
};

export default RadioWrapper;
