import React from "react";
import { Navigate, useRoutes, useNavigate, NavLink } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import MagicLinksTable from "./MagicLinksTable";
import MagicLinkForm from "./MagicLinkForm";

const MagicLinksPage = () => {
  const navigate = useNavigate();

  const tabs = [
    <NavLink key="ActiveLinks" to="#" className={checkTabIsActive}>
      Active Links
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "active",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Magic Links">
            <Button
              text="Generate Link"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />

          <MagicLinksTable />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg pb-16">
          <PageHeading
            title="Generate Magic Link"
            onGoBack={() => navigate(-1)}
          />
          <MagicLinkForm />
        </div>
      ),
    },
    {
      path: "*",
      element: <Navigate to="active" replace />,
    },
  ]);

  return <div className="flex flex-col">{element}</div>;
};

export default MagicLinksPage;
